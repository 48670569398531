import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const Home = () => import(/* webpackPrefetch: true */ '@/views/Home');
const Lobby = () => import(/* webpackPrefetch: true */ '@/views/Lobby');
const GameView = () => import(/* webpackPrefetch: true */ '@/views/GameView');
const Terms = () => import(/* webpackPrefetch: true */ '~/components/Terms');
const Loading = () => import(/* webpackPrefetch: true */ '@/views/Loading');

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/slots',
    name: 'lobbyIndex',
    component: Lobby,
  },
  {
    path: '/gameview',
    name: 'GameView',
    component: GameView,
  },
  {
    path: '/info/:type',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/loading',
    name: 'Loading',
    component: Loading,
  },
  { path: '*', component: Home },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

export default router;
