<template lang="pug">
div(class="modal-dialog" @click="$store.dispatch('actionSetShowRotateModal', false)")
  div(class="modal-content")
    div(class="modal-body")
      p {{ $t('common.tips.rotateDevice') }}
      img(src="@/assets/images/common/rotate.webp")
</template>

<script>
export default {
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/_mixins';
  .modal-dialog {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    transform: translate3d(0,0,0);
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0, 0.7);
    }
    .modal-content {
      z-index: 11000;
      padding: 20px;
      height: 300px;
      width: calc(var(--vh) * 90);
      color: #fff;
      position: fixed;
      left: 0;
      right: 0;
      margin: auto;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
      // &:before {
      //   content: "";
      //   position: absolute;
      //   bottom: 5px;
      //   left: 0;
      //   right: 0;
      //   margin: auto;
      //   width: 90%;
      //   height: 15px;
      //   background: radial-gradient(ellipse 50% 100% at bottom, #9ae2ff 0%, #3895bc 15%, transparent 100%);
      //   z-index: 2;
      // }
      // &:after {
      //   content: "";
      //   position: absolute;
      //   left: 3px;
      //   top: 3px;
      //   width: calc(100% - 6px);
      //   height: calc(100% - 6px);
      //   background: radial-gradient(circle at 50% -20%, #158cba 0, #2980a4 10%, #1c7ba2 30%, #0e2f5a 80%);
      // box-shadow: inset 0 2px 0 0px rgba(98,213,255,.4);
      // border: 3px solid #000;
      //   z-index: -2;
      //   border-radius: 22px;
      // }
      .modal-header {
        width: 100%;
        height: 35px;
        background-color: #3daae9;
        color: white;
        line-height: 35px;
      }
      .modal-body {
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        img {
          width: 100%;
          max-width: 170px;
          transform: rotate(90deg);
        }
        p {
          line-height: 30px;
          margin: 50px auto 10px;
          text-shadow: 0 1px 1px #000, 0 1px 1px #000, 0 1px 1px #000;
        }
      }
    }
  }
</style>
