import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import App from './App.vue';
import {
  Select, Option, Switch, Tooltip, Drawer, Loading, Message, Checkbox, Slider, CheckboxGroup
} from 'element-ui';
import '@/assets/tailwind.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'swiper/css/swiper.css';
import '@/assets/scss/global.scss';
import * as filters from 'web-slot-module/filters'; // global filters
import {
  ValidationObserver, ValidationProvider, extend, localize, setInteractionMode,
} from 'vee-validate';

import * as rules from 'vee-validate/dist/rules';
import * as customRules from 'web-slot-module/utils/veeValidate/customRules';
import localeEN from 'vee-validate/dist/locale/en.json';
import cookies from 'js-cookie';
import moment from 'moment';
import _ from 'lodash';
import { firestorePlugin } from 'vuefire';
import './registerServiceWorker';
import SubToolTip from '@/components/ToolTip';
// global custom components
import SubTableList from 'web-slot-module/components/tableList/tableWithoutPortrait';
import SubPagination from 'web-slot-module/components/pagination';
import VueLazyload from 'vue-lazyload';
import Zendesk from '@dansmaculotte/vue-zendesk';
import VueGtag from 'vue-gtag';
import Precision from 'web-slot-module//utils/Decimal';
import { track } from 'web-slot-module/utils/track.js';
import 'web-slot-module/directive';
import 'web-slot-module/utils/init';
import request from 'web-slot-module/utils/tc-request';

import smoothscroll from 'smoothscroll-polyfill';
import VueGtm from '@gtm-support/vue2-gtm';
import i18n from '@/language';
import store from './store';
import router from './router';
import requestWithLoading from '~/utils/requestWithLoading';

smoothscroll.polyfill();
// import '@/registerServiceWorker'
// 引用預設規則
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
// 自定義規則
Object.keys(customRules).forEach((rule) => {
  extend(rule, customRules[rule]);
});
localize('en', localeEN);

// https://vee-validate.logaretm.com/v3/api/validation-observer.html#scoped-slot-props
Vue.component('ValidationObserver', ValidationObserver);
// https://vee-validate.logaretm.com/v3/api/validation-provider.html#scoped-slot-props
Vue.component('ValidationProvider', ValidationProvider);

setInteractionMode('eager'); // vee valid mode

Vue.component('SubTableList', SubTableList);
Vue.component('SubPagination', SubPagination);
Vue.component('SubToolTip', SubToolTip);
Select.computed.readonly = function () {
  // trade-off for IE input readonly problem: https://github.com/ElemeFE/element/issues/10403
  const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode));

  return !(this.filterable || this.multiple || !isIE) && !this.visible || !this.filterable;
};
Vue.use(Select);
Vue.use(Switch);
Vue.use(Slider);
Vue.use(Tooltip);
Vue.use(Drawer);
Vue.use(Loading);
Vue.use(Option);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.prototype.$message = Message;
Vue.use(firestorePlugin);
Vue.use(VueLazyload);
Vue.use(Zendesk, {
  key: '',
  disabled: true,
  hideOnLoad: true,
  settings: {
    webWidget: {
      color: {
        theme: '#78a300',
      },
    },
  },
});

Vue.prototype.$cookie = cookies;
Vue.config.productionTip = false;
// register global utility filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
if (location.pathname !== '/' && !location.pathname.includes('info') && !location.pathname.includes('loading') && !params.cmd) {
  location.href = '/';
}

// const includes = process.env.VUE_APP_GA3_KEY ? [{ id: process.env.VUE_APP_GA3_KEY }] : undefined;

// Vue.use(VueGtag, {
//   config: { id: process.env.VUE_APP_GA_KEY },
//   includes,
// }, router);

// Vue.use(VueGtm, {
//   id: process.env.VUE_APP_GTM_KEY.split(','), // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
//   // queryParams: {
//   //   // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
//   //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
//   //   gtm_preview: 'env-4',
//   //   gtm_cookies_win: 'x',
//   // },
//   defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
//   compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
//   // nonce: '2726c7f26c', // Will add `nonce` to the script tag
//   enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
//   debug: process.env.VUE_APP_ENV !== 'prod', // Whether or not display console logs debugs (optional)
//   loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
//   vueRouter: router, // Pass the router instance to automatically sync with router (optional)
//   // ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
//   trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
// });

// if (process.env.ENV === 'production') {
//   Sentry.init({
//     Vue,
//     dsn: 'https://23190f9e1f584396b6b935330e52936d@o1290470.ingest.sentry.io/6524131',
//     integrations: [
//       new BrowserTracing({
//         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         tracingOrigins: ['localhost',
//           'https://play.ruai99.com/',
//           'https://agmpruai99.gpms365.net/',
//           'https://qa-agmpruai99.gpms365.net/',
//           /^\//],
//       }),
//     ],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 0.5,
//     logErrors: true,
//     release: process.env.SENTRY_RELEASE,
//   });
// }

store.watch((state) => state.initData.systemConfig, (systemConfig) => {
  const detectOrder = 60_000; // 1分鐘確認一次
  const googleTrackToolFlag = Object.hasOwnProperty.call(systemConfig, 'GOOGLE_TOOLS_TRACK_ID');
  if (googleTrackToolFlag) {
    let { ga_id, gtm_id } = systemConfig.GOOGLE_TOOLS_TRACK_ID;
    const gInitial = (isGa, id) => {
      let gaFn = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(...args) {
          window.dataLayer.push(args);
        }
        gtag('js', new Date());
        gtag('config', id);
      };
      let e = document.getElementsByTagName('script')[0];
      let $ = document.createElement('script');
      $.className = `${isGa ? 'ga' : 'gtm'}ScriptElement`;
      $.async = true;
      $.src = `https://www.googletagmanager.com/${isGa ? 'gtag/' : 'gtm.'}js?id=${id}`;
      if (isGa) {
        $.type = 'text/javascript';
        $.addEventListener('load', gaFn);
      } else {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
      }
      e.parentNode.insertBefore($, e);
    };
    if (ga_id && document.getElementsByClassName('gaScriptElement').length === 0) {
      ga_id.forEach((item) => gInitial(true, item));
    }
    if (gtm_id && document.getElementsByClassName('gtmScriptElement').length === 0) {
      gtm_id.forEach((item) => gInitial(false, item));
    }
  }

  // 輪詢取得會員錢包金額
  // setInterval(() => store.dispatch('financial/actionDetectDepositWithdrawOrder'), detectOrder);
});

Vue.prototype.$track = track;
Vue.prototype.$request = request;
Vue.prototype.$requestWithLoading = requestWithLoading;

new Vue({
  router,
  store,
  i18n,
  provide() {
    return {
      moment,
      _,
      Precision,
    };
  },
  render: (h) => h(App),
}).$mount('#app');
